'use client';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import BASE_URL from '@/components/constants';
import { Logout } from '@/components/auth/Logout';
import { useAuth } from '@/context/AuthContext';
import { toast } from 'react-hot-toast';
import { usePathname } from 'next/navigation';
import getToken from '@/hook/getToken';
const Cart = dynamic(() => import('@/components/cart'));
const Signin = dynamic(() => import('@/components/auth/Signin'));
const Wishlist = dynamic(() => import('@/components/wishlist'));
const Search = dynamic(() => import('./Search'), { ssr: false });
const NavLinks = dynamic(() => import('@/components/layout/navbar/NavLinks'));
const Sidebar = dynamic(() => import('./SIdebar'));
const Navbar = () => {
  const pathname = usePathname();
  const [isClicked, setIsClicked] = useState(false);
  const { isSignInOpen, setIsSignInOpen } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isBelowHero, setIsBelowHero] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleSignInClick = () => {
    setIsSignInOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getToken();
        if (!accessToken) return;
        // Replace with your actual access token
        const response = await fetch(`${BASE_URL}/v1/auth/b2c/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          let userName;

          if (data.userDetails.name) {
            userName = data.userDetails.name;
          } else {
            // Hardcoded username if name is not available
            userName = 'User';
          }

          setUserName(userName);
        } else {
          localStorage.removeItem('access_token');
          console.error('Failed to fetch data:', response.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [isSignInOpen]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if scrolled below hero section (you might need to adjust the value accordingly)
      if (window.scrollY > 250) {
        // setIsBelowHero(true);
        setIsClicked(false);
      } else {
        setIsBelowHero(false);
      }
    };

    // Add event listener for window scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav
      className={`sticky top-0 z-30 mx-auto max-w-screen-2xl shadow-md ${isBelowHero && pathname == '/'
        ? 'bg-transparent transition-colors duration-500'
        : 'bg-white transition-colors duration-500'
        }`}
    >
      {
        isSignInOpen && (
          <Signin isSignInOpen={isSignInOpen} />
        )
      }


      <div className='hidden items-center justify-between px-5 py-2 lg:flex'>
        <div>
          {isLargeScreen ? (
            <div onClick={() => setIsClicked(true)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-5 w-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5'
                />
              </svg>
            </div>
          ) : null}
        </div>
        <div className='sm:hidden md:block'>
          <Link href='/'>
            <img
              className='h-10 w-10'
              src='https://res.cloudinary.com/genx21/image/upload/v1733467899/images/ymrlt4hltxftbonhpsd3.png'
              alt='21genx logo'
            />
          </Link>
        </div>

        <div className='w-1/4'>
          <Search pathname={pathname} isBelowHero={isBelowHero} />
        </div>

        <div className='flex items-center gap-5'>
          <div className='flex justify-center items-center gap-5'>
            <Link className='text-sm py-1 px-4 rounded-lg border' href={'/about'} target=''>
              About Us
            </Link>
            <Link className='text-sm py-1 px-4 rounded-lg border' href={'/contact'} target='_blank'>
              Contact Us
            </Link>
          </div>
          <div
            className={`flex justify-center items-center ${pathname.startsWith('/checkout') ? 'hidden' : 'block'
              } `}
          >
            <Link href={`/user/wishlist`}>
              <Wishlist />
            </Link>
          </div>
          <div
            className={`flex justify-center items-center ${pathname.startsWith('/checkout') ? 'hidden' : 'block'
              }`}
          >
            <Cart />
          </div>
          <div className='relative'>
            {userName ? (
              <div
                className='mx-1 border-[#1D1D1F] px-2 py-1.5 text-sm font-medium capitalize'
                onMouseEnter={handlePopupOpen}
                onMouseLeave={handlePopupClose}
              >
                <span className='cursor-pointer'>
                  {userName === undefined
                    ? 'User'
                    : `Hi, ${userName}` || 'Hi,User'}
                </span>
                {isPopupOpen && (
                  <div
                    className='absolute right-0 top-7 z-10 border border-gray-300 bg-white p-2'
                    onMouseEnter={handlePopupOpen}
                    onMouseLeave={handlePopupClose}
                  >
                    <button className='mb-2 block w-full text-left'>
                      <Link href='/user'>My Account</Link>
                    </button>
                    <button
                      className='block w-full text-left'
                      onClick={() => toast.success('Logout successful')}
                    >
                      <Logout />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div
                className='mx-1 cursor-pointer rounded-md border-2 border-[#1D1D1F] px-4 py-1.5 text-sm font-medium'
                onClick={handleSignInClick}
              >
                Sign In
              </div>
            )}
          </div>
        </div>
      </div>

      {isClicked && (
        <NavLinks isClicked={isClicked} setIsClicked={setIsClicked} />
      )}

      {isLargeScreen ? null : <Sidebar />}
    </nav>
  );
};

export default Navbar;
