import { environment } from "@/components/constants";
export function trackRemoveFromCart(product) {
    if (environment !== 'prod') return;
    if (product && product.product_id && window.dataLayer) { // Ensure product data exists
        window.dataLayer.push({
            event: "remove_from_cart",
            product_id: product.product_id || "",
            index: 0,
            product_name: product.product_name || "", // Replace with actual product name
            product_category: product.child_category_name || "", // Replace with actual category
            price: Number(product.list_price) || 0, // Replace with actual price
            quantity: Number(product.order_qty) || 1, // Number of items removed
            discount: Number(product.discount),
            item_variant: product.variation_value1,
            currency: "INR" // Change currency if needed
        });
    }
}
